import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Submenu from "./Submenu"
import Bulten from "./Bulten"


function UfiSponsor() {
  return (
    <>

<div className="subpage3">
    <Container>
    <h3>HKF Fuarcılık AŞ, UFI Avrupa Semineri’nin Silver Sponsoru oldu</h3>
    </Container>

    </div>
    <Container className="mt-5">
      
    <Row>
        <Col md={9}>



          
         
       <p>

       5-17 Haziran 2015 tarihleri arasında Uluslararası Fuarcılar Birliği UFI, Avrupa Açık Yaz Semineri’ni İstanbul’da gerçekleştirdi. Hilton İstanbul 
       Bosphorus Otel'de düzenlenen seminerin ana teması, ‘Pazarlama Bütçesi için Rekabet - Pazarlama Karmasında Fuarların Gelecekteki Konumu’ idi.</p>

       <p>HKF Fuarcılık AŞ ise UFI’nin gerçekleştirdiği Avrupa Yaz Semineri’nin Silver Sponsorluğunu üstlendi. 34 ülkeden 144 katılımcının geldiği seminer 
programı çerçevesinde HKF Fuarcılık İstanbul’a gelen katılımcılara seminerler sonrasında unutulmaz bir İstanbul keyfi yaşattı. Seminerin ikinci gününün 
akşamı Boğaz’da gemi turuna çıkan katılımcılar, İstanbul Boğazı’nda akşam yemeği yedi. İstanbul’un en önemli sembollerinden biri olan Aya Sofya’yı da gezen 
katılımcılar, hem şehre hem de binlerce yıllık tarihe sahip Aya Sofya’ya hayran kaldı.</p>

<p>Katılımın yüksek olduğu İstanbul’daki seminer, Türkiye’nin dünya fuarcılığındaki öneminin de bir göstergesi niteliğindeydi. HKF Fuarcılık AŞ Genel Müdürü ve 
UFI Avrupa Bölüm Başkan Yardımcısı olan Bekir Çakıcı ise seminer ile ilgili olarak şu açıklamayı yaptı: ‘Başta İstanbul şehri olmak üzere Türkiye, fuarcılık 
sektörü için yeni bir pazar olduğunu bizlere kanıtladı. Artık yeterli doygunluğa ulaşan Avrupa’daki fuarlar yavaş yavaş doğuya doğru kaymaya başladı. 
Doğu ile Batı arasında bir köprü olan İstanbul ise gerek konumu gerekse de sahip olduğu imkânlar nedeniyle yeni yatırımcıların ilgi odağı olmaya başladı. 
UFI, Avrupa Yaz Semineri’ni İstanbul’da gerçekleştirerek bu eğilimin doğruluğunu bizlere gösterdi. HKF Fuarcılık ailesi olarak bizler de bu seminerin Silver 
Sponsorluğunu üstlenmekten dolayı büyük gurur duyduk’ şeklinde konuştu.</p>




        

         
        </Col>
        <Col md={3}>
         <Submenu/>
         <Bulten/>
        </Col>
      </Row>

    </Container>
    
    
    </>
  )
}

export default UfiSponsor