import Carousel from 'react-bootstrap/Carousel';
import hkf from "../img/hkf.jpg";
import busworld from "../img/busworld.jpg";
import foteg from "../img/foteg.jpg";
import viv from "../img/viv.jpg";
import { Link } from "react-router-dom";


function hkfCarousel() {
  return  (
    <Carousel>



<Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={hkf}
          alt="BUSWORLD TURKEY"/>
        <Carousel.Caption>
          <h1>Doğru karar, doğru hamle.</h1>
          <h3>Dünya standartlarında kaliteli uluslararası ihtisas fuarları</h3>
          
          <Link to="/hakkimizda" type="button" className="button-24">Devamı</Link>
        </Carousel.Caption>
      </Carousel.Item>
    

      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={busworld}
          alt="BUSWORLD TÜRKİYE"/>
        <Carousel.Caption>
          <h1>BUSWORLD TÜRKİYE</h1>
          <h3>Otobüs Endüstrisi ve Yan Sanayi Uluslararası İhtisas Fuarı</h3>
          <a href="https://www.busworldturkey.org/en" type="button" className="button-24">Devamı</a>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={foteg}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h1>FOTEG İSTANBUL</h1>
          <h3>Gıda İşleme Teknolojileri Uluslararası İhtisas Fuarı</h3>
          <a href="http://www.fotegistanbul.com/" type="button" className="button-24">Devamı</a>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={viv}
          alt="VIV TURKEY"
        />
        <Carousel.Caption>
          <h1>VIV SELECT  TÜRKİYE</h1>
          <h3>
          Tavukçuluk ve Teknolojileri Uluslararası İhtisas Fuarı </h3>
          <a href="http://www.vivturkey.com/" type="button" className="button-24">Devamı</a>
        </Carousel.Caption>
      </Carousel.Item>


     

      
    </Carousel>
  );
}

export default hkfCarousel;