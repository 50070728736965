import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Submenu from "./Submenu"
import Bulten from "./Bulten"
import U1 from '../img/ufi_genel.jpg';




function Ufi() {
  return (
    <>

<div className="subpage3">
    <Container>
    <h3>Türk Fuarcılık Tarihi’nde ilk kez 75. UFİ Kongresi'nin gururla ev sahipliğini yaptık !</h3>
    </Container>

    </div>
    <Container className="mt-5">


 
      
    <Row>
        <Col md={9}>


        <h4>75. UFİ Kongresi</h4>
          <div className='line2 mt-2 mb-3'></div>


     <img className='img-fluid' src={U1} alt="UFI" />
          
         
       <p className="mt-3">Dünya Fuar Endüstrisi Birliği UFİ’nin düzenlediği 75. UFİ Kongresi, Türk Fuarcılık Tarihi’nde ilk kez olarak 12 - 15 Kasım 2008 tarihleri  
        arasında HKF Fuarcılık A.Ş.’nin ev sahipliğinde İstanbul’da gerçekleştirildi. 13 Kasım Perşembe günü Resmi Açılışı yapıldı. Merkezi Fransa, 
        Paris’te olan Dünya Fuar Endüstrisi Birliği UFİ, 1925 yılında Avrupa’nın önde gelen 20 fuar organizasyonu tarafından kurulmuş olup, günümüzde 
        6 kıtada 80’den fazla ülkede 532 üyesi bulunmaktadır. Dünyada fuar endüstrisinin en saygın Birliği olan UFİ’nin Türkiye’den de 19 üyesi bulunmakta 
        ve UFİ tarafından üyelerine denetimler sonucu verilen Ufi Approved Event (UFİ Onaylı Fuar) Sertifikası, fuar endüstrisinde erişilebilecek olan 
        en saygın uluslararası kalite fuar sertifikası olarak kabul edilmektedir. Türkiye Odalar ve Borsalar Birliği Başkanı Sn. Rıfat Hisarcıklıoğlu, 
        İstanbul Büyükşehir Belediyesi Başkanı Sn. Kadir Topbaş ve İstanbul Ticaret Odası Başkanı Sn. Murat Yalçıntaş’ın evsahibine destek verdiği 75. 
        UFİ Kongresi’nde konuklar, evsahibi şirket olan HKF Fuarcılık A.Ş. tarafından ağırlandı. </p>
<p>

75. UFİ Kongresi’ne, fuar sektöründe dünya çapında, CEO düzeyinde 58 ülkeden 355 Delege ve 60 eş katıldı.</p>

<p>Açılış konuşmasını UFİ Başkanı Mr. John Shaw’ın yaptığı 75. UFİ Kongresi’nde şirketimiz Genel Müdürü Sn. Bekir Çakıcı “Türkiye ticari fırsatları 
zengin bir ülke’’ adlı sunumunda Türkiye’de fuarcılık ile ilgili iş fırsatlarının yanısıra fuarcılık tarihi hakkında da bilgiler verdi.</p>

<p>Türk Fuarcılık Tarihi’nde ilk kez olarak Türkiye’de düzenlenen 75. UFİ Kongresi’nin gala yemeği, TOBB (Türkiye Odalar ve Borsalar Birliği) 
tarafından Sait Halim Paşa Yalısı’nda organize edildi. 13 - 14 Kasım’da eşler için düzenlenen Boğaz ve müze gezisini, 15 Kasım’da bütün CEO ve 
eşlerin katıldığı Sultanahmet, Topkapı ve Ayasofya gezisi takip etti.</p>

<p>Her zaman zirvede olan metropol kentlerde yapılan UFİ Kongreleri’nin 75.’si sayesinde hem kentimiz İstanbul, Dünyada Zirvedeki Fuar Kentleri arasında 
yer aldı, hem de ülkemizin fuarcılık alanında sunduğu olanaklar dünyanın en tanınmış fuar alanları ve fuar organizasyon şirketlerinden 
gelen CEO’lara tanıtılmış oldu.


        </p>

         
        </Col>
        <Col md={3}>
         <Submenu/>
         <Bulten/>
        </Col>
      </Row>

    </Container>
    
    
    </>
  )
}

export default Ufi