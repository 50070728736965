import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Submenu from "./Submenu"
import Bulten from "./Bulten"

const Hakkimizda = () => {
  return (
<>
    <div className="subpage">
    <Container>
    <h3>Dünya standartlarında kaliteli uluslararası ihtisas fuarları</h3>
    </Container>

    </div>
    <Container className="mt-3">
      
    <Row>
        <Col md={9}>

          <h4>Hakkımızda</h4>
          <div className='line2 mt-2 mb-3'></div>
         
        <p> HKF Fuarcılık AŞ, Türkiye’de dünya standartlarında kaliteli uluslararası ihtisas fuarları organize etmek amacı ile 2002 
        yılında İstanbul’da kurulmuştur. 2004 yılından beri Dünya Fuar Endüstrisi Birliği UFI’nin asil üyesi olan şirketimizin Yönetim Kurulu Başkanı, 
        Bekir Çakıcı, 2006 yılından 2020 yılına kadar UFI Yönetim Kurulu üyesi oldu. Ayrıca 2014 yılından 2020 yılına kadar UFI’nin Avrupa Kıtası Üyeleri 
        Başkan Yardımcılığı’nı yaptı.</p>

      <p> HKF Fuarcılık’ın organize ettiği fuarlar arasında; Uluslararası Hayvancılık Teknolojileri ve Hayvansal Ürünler İhtisas Fuarı ANIMALIA İSTANBUL, 
        Gıda İşleme Teknolojileri Uluslararası İhtisas Fuarı FOTEG İSTANBUL, Tavukçuluk ve Teknolojileri Uluslararası İhtisas Fuarı VIV TÜRKİYE, Otobüs Endüstrisi 
        ve Yan Sanayi Uluslararası İhtisas Fuarı BUSWORLD TÜRKİYE, Kara, Deniz, Hava Taksi Üreticileri ve Yan Sanayi İhtisas Fuarı TAXIWORLD TÜRKİYE ile birlikte fuar 
        takvimine yeni eklenen Müzik, Dans ve Sahne Ekipmanları, Etkinlik Organizasyonu ve İletişim Teknolojileri Fuarı İSTANBUL MÜZİK VE DANS ile İçecek Teknolojileri 
        İhtisas Fuarı DRINKTEG İSTANBUL yer almaktadır.</p>

      <p> Hollandalı VNU Exhibitions Europe lisansı ile iki yılda bir düzenlenmekte olan VIV TÜRKİYE, Türkiye ve komşu ülkelerde düzenlenen en büyük tavukçuluk fuarıdır. 
        Buna ilave olarak, 2007 yılından bu yana iki yılda bir Belçika merkezli BUSWORLD KORTRIJK işbirliği ile düzenlenen Otobüs Endüstrisi ve Yan Sanayi Uluslararası 
        İhtisas Fuarı BUSWORLD TÜRKİYE, firmamızın yabancı ortakları ile birlikte gerçekleştirdiği fuarlarıdır.</p>

      <p> BUSWORLD TÜRKİYE ve VIV TÜRKİYE fuarlarının dışında FOTEG İSTANBUL ve ANIMALIA İSTANBUL fuarları da UFI Onaylı Fuar Sertifikası’nı taşımaya hak kazanmış olup UFI’nin 
        Dünya Uluslararası Fuarlar Takvimi’nde yer almaktadır. Türk Fuarcılık Tarihi’nde ilk kez HKF Fuarcılık, Dünya Fuar Endüstrisi Birliği UFI’nin 12-15 Kasım 2008 tarihleri 
        arasındaki 75. Kongresi’ne İstanbul’da ev sahipliği yapmıştır. Bu sayede şirketimiz, UFI Kongresi Ev Sahibi olma gururunu yaşamıştır.</p>

      <p> Haziran 2010’da şirketimiz, Avrupa Tarım Fuarları Organizatörleri Federasyonu EURASCO’ya Türkiye’den üyeliğe kabul edilen ilk ve tek şirket olma şerefine de sahip oldu. 
        Bu sayede hayvancılık ve gıda ürünleri sektörleri ile ilgili fuarlarımız olan; ANIMALIA İSTANBUL, FOTEG İSTANBUL ve VIV TÜRKİYE, EURASCO Üye Fuar sıfatına sahip oldular. 
        Sadece UFI Onaylı Fuar Sertifikalı fuarlara sahip şirketlerin kabul edildiği EURASCO, 1972 yılında kurulmuş olup, şu anda 17 ülkeden 25 üyesi bulunmaktadır.</p>

      <p> HKF Fuarcılık, fuar ajandasına eklediği yeni fuarlar ile yoluna devam etmektedir. 2015 yılında şirketimiz, Türkiye’nin ilk taksi fuarı olan 
        Kara, Deniz, Hava Taksi Üreticileri ve Yan Sanayi İhtisas Fuarı TAXIWORLD TÜRKİYE’yi gerçekleştirerek taksicilik sektöründe bir ilke imza attı. 2016 yılından 
        itibaren fuar ajandamıza eklenecek olan bir diğer fuarımız ise İSTANBUL MÜZİK VE DANS olacaktır. Müzik, Dans, Sahne Ekipmanları, Etkinlik Organizasyonu ve İletişim 
        Teknolojileri Fuarı olarak kapılarını açacak olan bu yeni fuarımız ile İstanbul, müziğin ve dansın merkezi haline gelecektir. 2016 yılında gerçekleşecek bir diğer yeni 
        fuarımız ise İçecek Teknolojileri İhtisas Fuarı DRINKTEG İSTANBUL. İçecek endüstrisinde kullanılan makine, ekipman ve yardımcı malzemeler ile birlikte sektörün her aşamasında 
        yer alan her türlü hizmeti kapsayan DRINKTEG İSTANBUL, içecek üretim endüstrisini aynı çatı altında toplayacak.</p>

      <p> Şirketimizin organize ettiği tüm fuarlarda, yerli ve yabancı olmak üzere uluslararası düzeyde katılımcılarla, profesyonel ziyaretçilerin bir araya geldiği, 
        en güncel sektörel gelişmelerin ve teknolojilerin sunulduğu, bilimsel forumların yer aldığı, sonucunda tüm sektör profesyonellerinin, mutlaka maddi ve manevi 
        kazanç sağlayacağı bir ortam yaratmak hedefimizdir. HKF Fuarcılık olarak bizler, her biri uzmanlık konularında deneyimli ve dinamik kadrosu ile fuarcılık sektöründe 
        uluslararası düzeyde yer almayı ilke edindik. Şirketimizin ve fuarlarımızın, fuarcılıkta en yüksek kalite göstergesi olan UFI üyeliğini ve sertifikasını alması ile bu 
        ilke doğrultusunda küresel düzeyde en önemli hedefe ulaşmış olduk. Şimdi ise amacımız, uluslararası tescilli kalitemizi korumamız kaydı ile fuarcılık sektöründe hizmetlerimize
         devam etmektir.</p>

         
        </Col>
        <Col md={3}>
         <Submenu/>
         <Bulten/>
        </Col>
      </Row>

    </Container>
    </>
  )
}

export default Hakkimizda