import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Submenu from "./Submenu"
import Bulten from "./Bulten"
import animalia from '../img/animalia.svg';
import busworld from '../img/busworld.svg';
import foteg from '../img/foteg.svg';
import taxiworld from '../img/taxiworld.svg';
import viv from '../img/viv.svg';

const Fuarlar = () => {
  return (
    <>
    
    
    <div className="subpage2">
    <Container>
    <h3>Dünya standartlarında kaliteli uluslararası ihtisas fuarları</h3>
    </Container>

    </div>
    <Container className="mt-5">

    <Row>
        <Col md={9}>
         
        <Container>

        <h4>Fuarlarımız</h4>
          <div className='line2 mt-2 mb-3'></div>


     


   



      <Row>
        <Col sm={3}><div className="logobox"><img src={busworld} alt="HKF" /></div></Col>
        <Col sm={9}>

        <p>
  
    

        <span className='tkv'>BUSWORLD TÜRKİYE 2024</span><br/>
  10. Otobüs Endüstrisi ve Yan Sanayi Uluslararası İhtisas Fuarı<br/>
29-31 MAY 2024<br/>
  İSTANBUL FUAR MERKEZİ / HALL 1-3<br/>
  www.busworldturkey.com
  </p>



        </Col>
      </Row>

      <Row>
<Col sm={12}>

<div className="line2 mt-4 mb-4"></div>

</Col>

    </Row>


      <Row>
        <Col sm={3}><div className="logobox"><img src={foteg} alt="HKF" /></div></Col>
        <Col sm={9}>
        <p>
  
    

        <span className='tkv'>FOTEG İSTANBUL 2025</span><br/>
  17. Gıda ve Gıda İşleme Teknolojileri Uluslararası İhtisas Fuarı<br/>
  24 - 26 NİSAN 2025<br/>
  İSTANBUL FUAR MERKEZİ HALL 5-6<br/>
  www.fotegistanbul.com
  </p>

</Col>
      </Row>


      

      <Row>
<Col sm={12}>

<div className="line2 mt-4 mb-4"></div>

</Col>

    </Row>



 <Row>
        <Col sm={3}><div className="logobox"><img src={viv} alt="HKF" /></div></Col>
        <Col sm={9}>

        <p>
  
    

  <span className='tkv'>VIV SELECT TÜRKİYE 2025</span><br/>
  11. Tavukçuluk ve Teknolojileri Uluslararası İhtisas Fuarı<br/>
  24 - 26 NİSAN 2025<br/>
  İSTANBUL FUAR MERKEZİ / HALL 2-4-8<br/>
  www.vivturkey.com
  </p>

</Col>
      </Row>



      
      <Row>
<Col sm={12}>

<div className="line2 mt-4 mb-4"></div>

</Col>

    </Row>


    <Row>
        <Col sm={3}><div className="logobox"><img src={animalia} alt="HKF" /></div></Col>
        <Col sm={9}>

        <p>
  
    

        <span className='tkv'>ANIMALIA İSTANBUL</span><br/>
        Uluslararası Hayvancılık Teknolojileri ve Hayvansal Ürünler İhtisas Fuarı<br/>

  </p>



        </Col>
      </Row>
      <Row>
<Col sm={12}>

<div className="line2 mt-4 mb-4"></div>

</Col>

    </Row>

    <Row>
        <Col sm={3}><div className="logobox"><img src={taxiworld} alt="HKF" /></div></Col>
        <Col sm={9}>

        <p>
  
    

        <span className='tkv'>TAXIWORLD TURKEY</span><br/>
        Kara, Deniz, Hava Taksi Üreticileri ve Yan Sanayi İhtisas Fuarı<br/>

       
  </p>



        </Col>
      </Row>

<br/>


    </Container>
         
        </Col>
        <Col md={3}>
         <Submenu/>
         <Bulten/>
        </Col>
      </Row>

    </Container>
   
    
    </>
  )
}

export default Fuarlar