import React from 'react'
import Nav from 'react-bootstrap/Nav';
function Submenu() {
  return (
   
<>

        <span className='menubaslik borderrad' style={{color:"rgba(0, 0, 0, 0.5)"}}>  <i className="fa-solid fa-chess-knight" style={{color:"rgba(0, 0, 0, 0.5)", marginRight:"6px"}}></i> Fuarlarımız</span> 
        <Nav className="flex-column">
          <Nav.Link href="https://www.busworldturkey.org/" target="_blank" style={{fontWeight:"700",borderBottom:"1px solid #ccc"}}> <i className="fa-solid fa-caret-right" style={{marginRight:"6px", color:"#ccc", fontSize:"16px"}}></i>BUSWORLD TÜRKİYE</Nav.Link>
          <Nav.Link href="http://www.fotegistanbul.com/" target="_blank"  style={{fontWeight:"700",borderBottom:"1px solid #ccc"}}><i className="fa-solid fa-caret-right" style={{marginRight:"6px", color:"#ccc", fontSize:"16px"}}></i>FOTEG İSTANBUL</Nav.Link>
          <Nav.Link href="http://www.vivturkey.com/" target="_blank" style={{fontWeight:"700"}}> <i className="fa-solid fa-caret-right" style={{marginRight:"6px", color:"#ccc", fontSize:"16px"}}></i> VIV TÜRKİYE</Nav.Link>
        
        </Nav>

    

        </>


  )
}

export default Submenu