import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Submenu from "./Submenu"
import Bulten from "./Bulten"


const Iletisim = () => {








  return (
    <>
    
    <div className="subpage5">
    <Container>
    <h3>Dünya standartlarında kaliteli uluslararası ihtisas fuarları</h3>
    </Container>

    </div>
    <Container className="mt-5">
      
    <Row>
        <Col md={9}>


          <div className="shadowcon">

          <p className='mt-3'>
<h5>HKF FUARCILIK AŞ</h5>
<div className="line2 mb-3"></div>
<i style={{color:"#95a8a4", fontSize: "12px", marginRight:"8px"}}  className="fa-solid fa-location-dot"></i> Barbaros Bulvarı 163 / 2 34349 Beşiktaş İstanbul Türkiye<br/>
<i style={{color:"#95a8a4", fontSize: "12px", marginRight:"8px"}} className="fa-solid fa-phone"></i> (212) 216 40 10<br/> 
<i style={{color:"#95a8a4", fontSize: "12px", marginRight:"8px"}}  className="fa-solid fa-fax"></i>  (212) 216 33 60 - 61<br/>
<i style={{color:"#95a8a4", fontSize: "12px", marginRight:"8px"}} className="fa-solid fa-envelope"></i> <a style={{color:"#95a8a4"}} href="mailto:info@hkf-fairs.com"> info@hkf-fairs.com</a><br/>

</p>



          </div>
         

    

         
        </Col>
        <Col md={3}>
         <Submenu/>
         <Bulten/>
        </Col>
      </Row>

    </Container>
    
    </>
  )
}

export default Iletisim