import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react'

const Hfooter = () => {
  return (
    <div className='hfooter bottom'>


<Container>
<Row>
<Col sm6>

<p className='mt-3'>
HKF FUARCILIK AŞ<br/>
<i style={{color:"#95a8a4", fontSize: "12px", marginRight:"8px"}}  className="fa-solid fa-location-dot"></i> Barbaros Bulvarı 163 / 2 34349 Beşiktaş İstanbul Türkiye<br/>
<i style={{color:"#95a8a4", fontSize: "12px", marginRight:"8px"}} className="fa-solid fa-phone"></i> (212) 216 40 10<br/> 
<i style={{color:"#95a8a4", fontSize: "12px", marginRight:"8px"}}  className="fa-solid fa-fax"></i>  (212) 216 33 60 - 61<br/>
<i style={{color:"#95a8a4", fontSize: "12px", marginRight:"8px"}} className="fa-solid fa-envelope"></i> <a style={{color:"#95a8a4"}} href="mailto:info@hkf-fairs.com"> info@hkf-fairs.com</a><br/>

</p>

</Col>
<Col sm6>

<div className="float-end mt-4">
    <a href="https://www.facebook.com/HKFTradeFairs" >  <i className="fa-brands fa-facebook btn-socialicons"></i></a>
    <a href="https://twitter.com/HKFTradeFairs" >   <i className="fa-brands fa-twitter btn-socialicons"></i></a>
    <a href="https://www.youtube.com/user/HKFTradeFairs" >   <i className="fa-brands fa-youtube btn-socialicons"></i></a>
    <a href="https://www.instagram.com/hkftradefairs" >  <i className="fa-brands fa-square-instagram btn-socialicons"></i></a>
   
    
    
    </div>

</Col>



</Row>
<div className="linealt"></div>
<Row>
<Col sm12><p className='mt-1 small'>HKF FUARCILIK A.Ş   © 2022  </p></Col>
</Row>
</Container>

    </div>
  )
}

export default Hfooter