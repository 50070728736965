import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import istanbul from "../img/istanbul.jpg";
import bekir from "../img/bekir_cakici.jpg";
import ufi from "../img/ufi.jpg";
import ufi2 from "../img/ufi2.jpg";
import ufi3 from "../img/ufi3.jpg";
import eurasco from "../img/eurasco.jpg";
import React from 'react'
import { Link } from "react-router-dom";



const Haberler = () => {
  return (

  
      <Container className='mt-3'>
        <h2 className='haber'>Bizden haberler<br /><i class="fa-sharp fa-solid fa-caret-down"></i></h2>
        <div className='line'></div>

        <Row className='mt-3'>

          <Col sm className='p-3'>
            <h3 className='newsh3'>HKF Fuarcılık AŞ, Global Fuar Endüstrisi Birliği Avrupa Semineri’nin Sponsoru oldu</h3>
            <div className='line mt-3'></div>
            <img
              className="img-fluid mt-3"
              src={istanbul}
              alt="istanbul" />
            <br />

            <p className='mt-3'>15-17 Haziran 2015 tarihleri arasında Uluslararası Fuarcılar Birliği UFI, Avrupa Açık Yaz
              Semineri’ni İstanbul’da gerçekleştirdi. Hilton İstanbul Bosphorus Otel'de düzenlenen seminerin ana teması,
              ‘Pazarlama Bütçesi için Rekabet - Pazarlama Karmasında Fuarların Gelecekteki Konumu’ idi.
              HKF Fuarcılık AŞ ise UFI’nin gerçekleştirdiği Avrupa Yaz Semineri’nin Silver Sponsorluğunu üstlendi. 34 ülkeden 144 katılımcının
              geldiği seminer programı çerçevesinde HKF Fuarcılık...
            </p>


          
            <Link to="/Ufisponsor" type="button" class="btn btn-warning">Devamı</Link>

          </Col>



          <Col sm className='p-3'><h3 className='newsh3'>Türk fuarcılık tarihinde ilk kez 75. UFI kongresinin gururla ev sahipliğini yaptık</h3>
            <div className='line mt-3'></div>

            <img
              className="img-fluid mt-3"
              src={ufi3}
              alt="ufi" />
            <br />
            <p className='mt-3'>

              Dünya Fuar Endüstrisi Birliği UFİ’nin düzenlediği 75. UFİ Kongresi, Türk Fuarcılık Tarihi’nde ilk kez olarak 12 - 15 Kasım 2008 tarihleri arasında
              HKF Fuarcılık A.Ş.’nin ev sahipliğinde İstanbul’da gerçekleştirildi. 13 Kasım Perşembe günü Resmi Açılışı yapıldı. Merkezi Fransa, Paris’te olan Dünya Fuar
              Endüstrisi Birliği UFİ, 1925 yılında Avrupa’nın önde gelen 20 fuar organizasyonu tarafından kurulmuş olup, günümüzde 6 kıtada 80’den fazla ülkede 532 üyesi bulunmaktadır.

            </p>

           

            <Link to="/ufi" type="button" class="btn btn-warning">Devamı</Link>

          </Col>



          <Col sm className='p-3'><h3 className='newsh3'>HKF Fuarcılık, Avrupa Tarım Fuarları Organizatörleri Federasyonu'na üye oldu</h3>
            <div className='line mt-3'></div>

            <img
              className="img-fluid mt-3"
              src={eurasco}
              alt="ufi" />
            <br />
            <p className='mt-3'>UFI Onaylı Fuar Sertifikalı fuarlara sahip şirketlerin kabul edildiği AVRUPA TARIM FUARLARI ORGANİZATÖRLERİ FEDERASYONU
              olan EURASCO, 1972 yılında kurulmuş olup, 24 ülkeden 32 üyesi bulunmaktadır. Tarım fuarı organize eden şirketlere danışmanlık yapan ve kendi
              bünyesinde bulunan üyelerini her yıl aynı çatı altında toplayarak tavsiyelerde bulunan bir organizasyondur. Böylelikle ana ilkesi dünya standartlarında
              kaliteli uluslararası ihtisas fuarları düzenlemek olan HKF Fuarcılık A.Ş., EURASCO’ya Türkiye’den üyeliğe ilk ve tek kabul edilen şirket olma şerefine sahip oldu.</p>
          </Col>




        </Row>

        <div className='line'></div>

        <Row>

          <Col sm className='p-3'><h3 className='newsh3'>Bekir Çakıcı, 7 dönem UFI’nin Yönetim Kurulu Üyeliği yaptı</h3>

            <div className='line mt-3'></div>
            <img
              className="img-fluid mt-3"
              src={ufi}
              alt="ufi" />
            <br />

            <p className='mt-3'>HKF Fuarcılık Yönetim Kurulu Başkanı Bekir Çakıcı, 2006 yılından başlamak üzere 7 dönem UFI’nin Yönetim Kurulu Üyeliği’ni 2020 yılına kadar yaptı.</p>

          </Col>



          <Col sm className='p-3'>

            <h3 className='newsh3'>Bekir Çakıcı, 2.kez UFI’nin Avrupa Kıtası Bölüm Başkan Vekilliğini yaptı</h3>

            <div className='line mt-3'></div>
            <img
              className="img-fluid mt-3"
              src={bekir}
              alt="bekir_cakici" />
            <br />

            <p className='mt-3'>HKF Fuarcılık Yönetim Kurulu Başkanı Bekir Çakıcı, Global Fuar Endüstrisi Birliği UFI’nin Avrupa Kıtası
              Bölüm Başkan Vekilliğine 2017 yılında 2. kez seçildi ve 2020 yılına kadar bu görevi yaptı.</p>

          </Col>



          <Col sm className='p-3'><h3 className='newsh3'>UFI Avrupa Bölüm Başkan Vekilliğine Yeni İsim: Bekir Çakıcı</h3>
            <div className='line mt-3'></div>

            <img
              className="img-fluid mt-3"
              src={ufi2}
              alt="ufi" />
            <br />
            <p className='mt-3'>HKF Fuarcılık A.Ş. Yönetim Kurulu Başkanı Bekir Çakıcı 2014 yılında, Global Fuar Endüstrisi Birliği UFI’nin Avrupa Kıtası Bölüm Başkan
              Vekilliğine seçilen ilk Türk işadamı oldu.</p>

          </Col>




        </Row>


      </Container>
    

      

  )
}

export default Haberler