import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Submenu from "./Submenu"
import Bulten from "./Bulten"
import Button from 'react-bootstrap/Button';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';




function Iky() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    var status=document.getElementById("status");

    emailjs.sendForm('service_k0ibptg', 'template_68evdqh', form.current, 'user_r0D2dx6qFC0P22oeN97gb')
      .then((result) => {
          console.log(result.text);
          status.innerHTML= "<p className='success'> Mesaj Gönderildi </p>";
          
      }, (error) => {
        status.innerHTML="<p className='danger'> Bir hata oluştu. Lütfen daha sonta tekrar deneyin. </p>";
      });
  };



  return (
    

<>
    <div className="subpage4">
    <Container>
    <h3 style={{color:"rgb(65, 106, 89)"}}>HKF Fuarcılık A.Ş. İş Başvuru Formu</h3>
    </Container>

    </div>
    <Container className="mt-5">
      
    <Row>
        <Col md={9} className="p-1">



      <div className="shadowcon">
      <h4>Başvuru formu</h4>
          <div className='line mt-2 mb-3'></div>



      <form ref={form} onSubmit={sendEmail}>
      <label className='mb-2 mt-2'>İsim</label>
      <input className='d-block w-100 mt-1 p-2  form-control' type="text" id="name" placeholder='isim-soyisim' required name="name" />
      <label className='mb-2 mt-2'>Email</label>
      <input type="email" className='d-block w-100 mt-1 p-2  form-control' id="email" placeholder="e-mail adres" required name="email" />
      <label className='mb-2 mt-2'>Telefon</label>
      <input type="number" className='d-block w-100 mt-1 p-2 form-control' id="telefon" placeholder='telefon' required name="telefon" />
      <label className='mb-2 mt-2'>CV Yükle</label>
      <input type="file" className='d-block w-100 mt-1 p-2  form-control' id="cv" placeholder='cv yükle' name="cv" />
      <div className="status mt-3" id="status"> </div>
      <span className='small mt-3'>* CV'nizi doc, docx veya PDF olarak yükleyebilirsiniz</span><br/>
      <Button type='submit' className="mt-2 mb-5" variant="success">Formu gönder</Button>
      
    </form>

</div>





  



    



         
        </Col>

       

        <Col md={3}>
         <Submenu/>
         <Bulten/>
        </Col>
      </Row>

    </Container>
    </>


  )
}

export default Iky;