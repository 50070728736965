import React from 'react'
import { Formik } from 'formik';


function Bulten() {
  return (
    <>
      <span className='menubaslik borderrad mt-3' style={{ color: "rgba(0, 0, 0, 0.5)" }}> <i className="fa-solid fa-envelope" style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "6px" }}></i> E-bülten</span>

      <Formik
        initialValues={{ email: '' }}

        onSubmit={(values) => {
          console.log(values)
          document.getElementById("memail").value="Adres eklendi...";
          setInterval(()=>(document.getElementById("memail").value="E-mail adres"), 3000);
          clearInterval();
        }}
      >

        {

          ({handleSubmit, handleChange}) => (

            <form onSubmit={handleSubmit} onChange={handleChange} className='p-2'>

              <input className="d-block w-100 mb-1  mt-1 form-control" id="memail" name="memail" placeholder="E-mail adres" type="email" required />
              <button className='btn btn-success btn-md mt-2' type="submit">E-bülten listesine ekle</button>
            </form>

          )

        }


      </Formik>

    </>
  )
}

export default Bulten